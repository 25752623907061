import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Post({ title, html }) {
  return (
    <div className={styles.post}>
      <h2 className={styles.title}>{title}</h2>

      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: html }}
      />

      <div className={styles.footer}>
        LOVE & PEACE
        <br />- Mr Milk Love
      </div>
    </div>
  );
}

Post.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

export default Post;
